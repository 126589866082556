"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const projections_1 = require("~/openlayers/projections");
/**
 * Ce module permet de générer les fonds de carte issus de l'IGN espagnol.
 * Inspiré de http://www.peterrobins.co.uk/it/olchangingprojection.html
 */
const SpainIdee = {
    layersOptions: [
        {
            name: 'Topo Espagne',
            layerUrl: 'https://www.ign.es/wms-inspire/mapa-raster',
            layer: 'mtn_rasterizado',
            permalinkId: 'spain.raster',
            imagePath: 'img/spain-raster.jpg'
        }
    ],
    // Config générale des layers à construire
    sharedOptions: {
        projection: projections_1.spainProjection,
        maxExtent: new OpenLayers.Bounds(-100000, 3950000, 1150000, 4900000),
        resolutions: [1800, 900, 450, 225, 120, 50, 25, 10, 4.5, 3, 2, 1, 0.5],
        geocoderZoom: 8,
        attribution: `
      <div>
        IGN topo maps from <a target="_blank" href="http://www.idee.es/">IDEE</a>
      </div>`,
        customScales: [
            {
                scale: 500000,
                ratio: 2
            },
            {
                scale: 250000,
                ratio: 2
            },
            {
                scale: 100000,
                ratio: 2
            },
            {
                scale: 50000,
                ratio: 2
            },
            {
                scale: 25000,
                ratio: 2
            }
        ]
    },
    /**
     * Crée un fond de carte issu de Géoportail.
     */
    buildLayer(layerOptions) {
        const layer = new OpenLayers.Layer.WMS(layerOptions.name, layerOptions.layerUrl, { layers: layerOptions.layer }, Object.assign(Object.assign({}, this.sharedOptions), { permalinkId: layerOptions.permalinkId, imagePath: layerOptions.imagePath }));
        return layer;
    }
};
exports.default = SpainIdee;
