"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Librairie utilitaire pour gérer les permaliens.
 */
class Permalink {
    constructor() {
        this.providers = [];
    }
    /**
     * Ajoute un module en tant que subscriber de JGN.Permalink.
     * Il sera sollicité pour fournir ses paramètres lors de l'appel de
     * la fonction JGN.Permalink.buildUrl
     */
    register(provider) {
        this.providers.push(provider);
    }
    getData() {
        // Il y a du cache !
        if (!this.data) {
            this.data = {};
            // ne pas utiliser le document.location.hash qui decode les caractères spéciaux dont '='
            const urlHashes = document.location.href.split('#!');
            if (urlHashes.length > 1) {
                const urlHash = urlHashes[1];
                const urlVars = urlHash.split('&');
                for (var i = 0; i <= (urlVars.length); i++) {
                    if (urlVars[i]) {
                        const urlVarPair = urlVars[i].split('=');
                        this.data[urlVarPair[0]] = urlVarPair[1];
                    }
                }
            }
        }
        return this.data;
    }
    /**
     * Construit l'url (permalien), en appelant la fonction 'extractSettings'
     * de tous ses modules subscribers.
     */
    buildUrl(page) {
        let relativePath = window.location.pathname;
        relativePath = relativePath.substring(0, relativePath.lastIndexOf('/') + 1);
        if (page) {
            relativePath += page;
        }
        const params = [];
        for (const provider of this.providers) {
            if (typeof (provider.providePermalink) === 'function') {
                const settings = provider.providePermalink();
                for (const [key, value] of Object.entries(settings)) {
                    params.push(key + '=' + value);
                }
            }
        }
        return [relativePath, '#!', params.join('&')].join('');
    }
}
exports.default = Permalink;
