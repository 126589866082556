"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.captureException = exports.initSentry = void 0;
const browser_1 = require("@sentry/browser");
const tracing_1 = require("@sentry/tracing");
function initSentry(environment) {
    environment = environment || 'dev';
    (0, browser_1.init)({
        dsn: "https://e73176743f304363aa8e9ea43d82da91@o4503988532084736.ingest.sentry.io/4503988534902784",
        integrations: [new tracing_1.BrowserTracing()],
        enabled: environment !== 'dev',
        environment: environment,
        release: VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
exports.initSentry = initSentry;
exports.captureException = browser_1.captureException;
