"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Module permettant de générer les fonds de carte OpenStreetMap.
 */
const Google = {
    layersOptions: [
        {
            name: "Google Physical",
            type: google.maps.MapTypeId.TERRAIN,
            permalinkId: "google.physical",
            imagePath: "img/google-physical.jpg"
        },
        {
            name: "Google Streets",
            // Default
            type: google.maps.MapTypeId.ROADMAP,
            numZoomLevels: 20,
            permalinkId: "google.streets",
            imagePath: "img/google-streets.jpg"
        },
        {
            name: "Google Hybrid",
            type: google.maps.MapTypeId.HYBRID,
            numZoomLevels: 20,
            permalinkId: "google.hybrid",
            imagePath: "img/google-hybrid.jpg"
        },
        {
            name: "Google Satellite",
            type: google.maps.MapTypeId.SATELLITE,
            numZoomLevels: 22,
            permalinkId: "google.satellite",
            imagePath: "img/google-satellite.jpg"
        }
    ],
    // Config générale des layers à construire
    sharedOptions: {
        geocoderZoom: 12,
    },
    /**
     * Crée le fond de carte Google
     */
    buildLayer(layerOptions) {
        const options = Object.assign(Object.assign({}, this.sharedOptions), layerOptions);
        var layer = new OpenLayers.Layer.Google(layerOptions.name, options);
        layer.events.register('visibilitychanged', layer, onVisibilityChanged);
        return layer;
    },
};
function onVisibilityChanged(e) {
    var layer = e.object;
    if (layer.getVisibility()) {
        $('.olLayerGoogleCopyright').show();
    }
    else {
        $('.olLayerGoogleCopyright').hide();
    }
}
OpenLayers.Layer.Google.RESOLUTIONS =
    [1.40625,
        1.703125,
        1.3515625,
        1.17578125,
        1.087890625,
        1.0439453125,
        1.02197265625,
        1.010986328125,
        1.0054931640625,
        1.00274658203125,
        1.001373291015625,
        1.0006866455078125,
        1.00034332275390625,
        1.000171661376953125,
        1.0000858306884765625,
        1.00004291534423828125,
        1.00002145767211914062,
        1.00001072883605957031,
        1.00000536441802978515,
        1.00000268220901489257,
        1.0000013411045074462891,
        1.00000067055225372314453];
exports.default = Google;
