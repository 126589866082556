"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAPER = void 0;
const utils_1 = require("~/utils/utils");
/**
 * Dimensions génériques de feuilles.
 */
exports.PAPER = {
    a5: [14.85, 21],
    a4: [21, 29.7],
    a3: [29.7, 42],
    a2: [42, 59.4],
    a1: [59.4, 84.1],
    a0: [84.1, 118.9]
};
/**
 * Singleton qui permet de gérer le format des pages
 */
class PageFormatter {
    constructor() {
        this.styles = {};
    }
    /**
     * Redimensionne la page
     */
    updatePageSize(settings) {
        // Calcule les dimensions de la page
        const enrichedSettings = this.enrichSize(settings);
        var resolution = (0, utils_1.getScreenResolution)();
        // On retire les anciens styles
        if (this.styles.screen != undefined)
            this.styles.screen.remove();
        if (this.styles.print != undefined)
            this.styles.print.remove();
        // Attention, ces styles ne doivent pas s'appliquer lors de l'impression
        this.styles.screen = $(['<style media="screen" type="text/css">',
            '.page { ',
            'height: ', enrichedSettings.height, 'cm; ',
            'width: ', enrichedSettings.width, 'cm; ',
            '}\n',
            '.page-inner { ',
            'margin-left: ', enrichedSettings.margin_left, 'cm; ',
            'margin-right: ', enrichedSettings.margin_right, 'cm; ',
            'margin-top: ', enrichedSettings.margin_top, 'cm; ',
            'margin-bottom: ', enrichedSettings.margin_bottom, 'cm; ',
            '}\n',
            '.printer .page-inner { ',
            // On enlève 2 px pour prendre de la marge
            'width: ', ((enrichedSettings.width - enrichedSettings.margin_left - enrichedSettings.margin_right) - 2 / resolution * 100), 'cm !important; ',
            'height: ', ((enrichedSettings.height - enrichedSettings.margin_top - enrichedSettings.margin_bottom) - 2 / resolution * 100), 'cm !important; ',
            '}\n',
            '</style>'].join('')).appendTo('head');
        // Lors des impressions, on utilise l'expression @print
        this.styles.print = $(['<style media="print" type="text/css">',
            '@page { ',
            'size: ', enrichedSettings.width, 'cm ', enrichedSettings.height + 'cm; ',
            'margin-left: ', enrichedSettings.margin_left, 'cm; ',
            'margin-right: ', enrichedSettings.margin_right, 'cm; ',
            'margin-top: ', enrichedSettings.margin_top, 'cm; ',
            'margin-bottom: ', enrichedSettings.margin_bottom, 'cm; ',
            '}\n',
            '.page-inner { ',
            // On enlève 2 px pour prendre de la marge
            'width: ', ((enrichedSettings.width - enrichedSettings.margin_left - enrichedSettings.margin_right) - 2 / resolution * 100), 'cm !important; ',
            'height: ', ((enrichedSettings.height - enrichedSettings.margin_top - enrichedSettings.margin_bottom) - 2 / resolution * 100), 'cm !important; ',
            '}\n',
            '</style>'].join('')).appendTo('head');
    }
    enrichSize(settings) {
        const enrichedSettings = settings;
        // Dimension de la page
        let dim;
        switch (enrichedSettings.paper) {
            case 'a0':
                dim = exports.PAPER.a0;
                break;
            case 'a1':
                dim = exports.PAPER.a1;
                break;
            case 'a2':
                dim = exports.PAPER.a2;
                break;
            case 'a3':
                dim = exports.PAPER.a3;
                break;
            case 'a5':
                dim = exports.PAPER.a5;
                break;
            case 'a4':
            default:
                dim = exports.PAPER.a4;
        }
        switch (enrichedSettings.orientation) {
            case 'landscape':
                enrichedSettings.width = dim[1];
                enrichedSettings.height = dim[0];
                break;
            case 'portrait':
            default:
                enrichedSettings.width = dim[0];
                enrichedSettings.height = dim[1];
        }
        return enrichedSettings;
    }
}
exports.default = PageFormatter;
