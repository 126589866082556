"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.providePermalink = exports.parsePermalink = void 0;
const DEFAULT = {
    lat: 45.833333,
    lon: 2.731525,
    zoom: 6,
    baseLayer: 'ign.carte',
};
/**
 * Extrait les paramètres issues de l'url:
 * Positionnement de la carte (longitude, latitude et zoom)
 */
function parsePermalink(data) {
    return {
        lat: $.isNumeric(data['lat']) ? parseFloat(data['lat']) : DEFAULT.lat,
        lon: $.isNumeric(data['lon']) ? parseFloat(data['lon']) : DEFAULT.lon,
        zoom: $.isNumeric(data['zoom']) ? parseInt(data['zoom']) : DEFAULT.zoom,
        baseLayer: data['baseLayer'] ? decodeURIComponent(data['baseLayer']) : DEFAULT.baseLayer,
    };
}
exports.parsePermalink = parsePermalink;
/**
   * Retourne les paramètres à introduire dans l'url pour reproduire la même
   * carte.
   */
function providePermalink(olMap) {
    const data = {};
    // Position de la carte
    const center = olMap.getCenter().clone();
    center.transform(olMap.baseLayer.projection, olMap.displayProjection);
    data['lat'] = center.lat;
    data['lon'] = center.lon;
    data['zoom'] = olMap.getZoom();
    const baseLayer = olMap.baseLayer;
    if (baseLayer.hasCustomScales()) {
        data['max_ratio'] = baseLayer.getRatio();
    }
    // BaseLayer
    data['baseLayer'] = encodeURIComponent(baseLayer.permalinkId);
    return data;
}
exports.providePermalink = providePermalink;
