"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OVERLAP = void 0;
const FormatterForm_1 = require("~/view/FormatterForm");
const projections_1 = require("~/openlayers/projections");
exports.OVERLAP = 0.05;
/**
 * Controleur permettant de choisir une zone d'impression.
 *
 * Toutes les coordonnées ici seront exprimées dans le système de projection de la baseLayer
 * de la carte.
 */
class PrintPreview extends OpenLayers.Control {
    constructor(options) {
        super(options);
        this.pageFormatter = options.pageFormatter;
        if (!options.form)
            return;
        this.form = options.form;
        // Ajoute les échelles possibles
        var scales = [500000, 250000, 100000, 50000, 25000];
        this.form.updateScales(scales);
        // Initialisation des données du formulaire
        const permalink = options.permalink;
        permalink.register(this);
        const settings = this.parsePermalink(permalink.getData());
        this.form.setSettings(settings);
        const $el = this.form.$el;
        // Dès qu'il y a modif du format, on met à jour la zone d'impression
        $el.on("submit", this.updatePrintBox.bind(this));
        // Gestion des boutons particuliers
        $('#print-center', $el).on("click", (e) => {
            e.preventDefault();
            var bounds = this.getBounds();
            this.map.zoomToExtent(bounds);
        });
        $('#screen-center', $el).on("click", (e) => {
            e.preventDefault();
            var center = this.map.getCenter();
            this.updatePrintBox(center);
        });
    }
    /**
     * Appelé lorsque la carte est attribuée au Control.
     */
    setMap(map) {
        super.setMap(map);
        this.printZoneOverlay = new OpenLayers.Layer.Vector("Zone d'impression");
        this.map.addLayer(this.printZoneOverlay);
        this.dragFeature = new OpenLayers.Control.DragFeature(this.printZoneOverlay);
        this.map.addControl(this.dragFeature);
        this.dragFeature.activate();
        this.style = Object.assign(Object.assign({}, OpenLayers.Feature.Vector.style['default']), { fillColor: "#4D90FE", fillOpacity: 0.3, strokeColor: "#0066BB" });
        this.map.events.register('mapReady', this, (event) => {
            // Au démarrage, le centre de la zone d'impression sera celui de l'écran
            var center = this.map.getCenter();
            this.updatePrintBox(center);
            // Si on change de projection, il faut recentrer la zone d'impression
            this.map.events.register('changeprojection', this, (event) => {
                var center = this.getCenter();
                center.transform(event.oldProjection, event.newProjection);
                this.updatePrintBox(center);
            });
        });
    }
    /**
     * Met à jour l'aperçu de la zone d'impression.
     *
     * @param printCenter: centre de la zone. Doit être exprimée dans le système de coordonnées de la
     * 					baseLayer. Si ce centre n'est pas transmis, on prend le centre actuel de la zone.
     */
    updatePrintBox(printCenter) {
        const form = this.form;
        this.printZoneOverlay.removeAllFeatures({ silent: true });
        // Attention aux restrictions de licences
        const restrictions = this.getRestrictions();
        form.updateRestrictions(restrictions);
        // Calcule les dimensions de la page (width et height)
        const partialSettings = form.getSettings();
        const settings = this.pageFormatter.enrichSize(partialSettings);
        const scale = partialSettings.scale;
        var width = (settings.width - settings.margin_left - settings.margin_right) // largeur d'une page
            * (settings.width_count // nombre de pages
                - exports.OVERLAP * Math.max(0, settings.width_count - 1)) // espace en double à cause des superposition
            / 100; // de cm à m;
        var height = (settings.height - settings.margin_top - settings.margin_bottom) // hauteur d'une page
            * (settings.height_count // nombre de pages
                - exports.OVERLAP * Math.max(0, settings.height_count - 1)) // espace en double à cause des superposition
            / 100; // de cm à m;
        var center = (printCenter && printCenter.lat && printCenter.lon) ? printCenter : this.getCenter();
        var projFactor = this.map.getProjectionFactor(center);
        var bounds = new OpenLayers.Bounds(center.lon - (width / 2) * scale * projFactor, // left
        center.lat - (height / 2) * scale * projFactor, // bottom
        center.lon + (width / 2) * scale * projFactor, // right
        center.lat + (height / 2) * scale * projFactor // top
        );
        var box = bounds.toGeometry();
        var displayCenter = center.clone();
        var displayBounds = bounds.clone();
        console.log("updatePrintBox", {
            center: displayCenter,
            width: width * scale,
            height: height * scale,
        });
        this.printZone = new OpenLayers.Feature.Vector(box);
        this.printZone.style = this.style;
        this.printZoneOverlay.addFeatures([this.printZone]);
    }
    /**
     * Extrait les paramètres issues de l'url.
     */
    parsePermalink(data) {
        const pageSettings = {
            // Dimension et orientation du papier
            paper: data['paper'] || FormatterForm_1.DEFAULT.paper,
            orientation: data['orientation'] || FormatterForm_1.DEFAULT.orientation,
            // Marges
            margin_left: $.isNumeric(data['margin_left']) ? data['margin_left']
                : FormatterForm_1.DEFAULT.margin_left,
            margin_right: $.isNumeric(data['margin_right']) ? data['margin_right']
                : FormatterForm_1.DEFAULT.margin_right,
            margin_top: $.isNumeric(data['margin_top']) ? data['margin_top']
                : FormatterForm_1.DEFAULT.margin_top,
            margin_bottom: $.isNumeric(data['margin_bottom']) ? data['margin_bottom']
                : FormatterForm_1.DEFAULT.margin_bottom,
            // Nombre de pages
            height_count: $.isNumeric(data['height_count']) ? data['height_count']
                : FormatterForm_1.DEFAULT.height_count,
            width_count: $.isNumeric(data['width_count']) ? data['width_count']
                : FormatterForm_1.DEFAULT.width_count,
        };
        return Object.assign(Object.assign({}, pageSettings), { 
            // Résolution de la carte
            scale: $.isNumeric(data['scale']) ? parseFloat(data['scale']) : data['scale'], 
            // Centre de la zone d'impression
            print_center_lat: data['print_center_lat'], print_center_lon: data['print_center_lon'] });
    }
    /**
     * Retourne les paramètres à introduire dans l'url pour reproduire la même
     * mise en page.
     */
    providePermalink() {
        const settings = this.form.getSettings();
        const data = {};
        if (settings.paper != FormatterForm_1.DEFAULT.paper)
            data['paper'] = settings.paper;
        if (settings.orientation != FormatterForm_1.DEFAULT.orientation)
            data['orientation'] = settings.orientation;
        if (settings.margin_left != FormatterForm_1.DEFAULT.margin_left)
            data['margin_left'] = settings.margin_left;
        if (settings.margin_right != FormatterForm_1.DEFAULT.margin_right)
            data['margin_right'] = settings.margin_right;
        if (settings.margin_top != FormatterForm_1.DEFAULT.margin_top)
            data['margin_top'] = settings.margin_top;
        if (settings.margin_bottom != FormatterForm_1.DEFAULT.margin_bottom)
            data['margin_bottom'] = settings.margin_bottom;
        if (settings.scale)
            data['scale'] = settings.scale;
        if (settings.height_count != FormatterForm_1.DEFAULT.height_count)
            data['height_count'] = settings.height_count;
        if (settings.width_count != FormatterForm_1.DEFAULT.width_count)
            data['width_count'] = settings.width_count;
        var center = this.getCenter();
        center.transform(projections_1.mapProjection, projections_1.gpsProjection);
        data['print_center_lat'] = center.lat;
        data['print_center_lon'] = center.lon;
        return data;
    }
    /**
     * Retourne la zone couverte par l'impression, exmprimée dans le système de coordonnées
     * de la baseLayer de la carte.
     */
    getBounds() {
        const bounds = this.printZone.geometry.bounds;
        return bounds;
    }
    /**
     * Retourne le centre de la zone couverte par l'impression, exmprimé dans le système de coordonnées
     * de la baseLayer de la carte.
     */
    getCenter() {
        const bounds = this.printZone.geometry.bounds;
        const center = new OpenLayers.LonLat((bounds.left + bounds.right) / 2, (bounds.top + bounds.bottom) / 2);
        return center;
    }
    getRestrictions() {
        const restrictions = {};
        // Pas de restriction si pas de carte
        if (this.map == undefined || this.map.baseLayer == undefined) {
            return restrictions;
        }
        // Rstrictions dans le cas d'un fond de carte limité à du A4
        if (this.map.baseLayer.limitSizeToA4) {
            restrictions.limitSizeToA4 = true;
        }
        return restrictions;
    }
}
exports.default = PrintPreview;
Object.defineProperties(PrintPreview.prototype, {
    CLASS_NAME: {
        value: "JGN.Control.PrintPreview"
    }
});
JGN.PrintPreview = PrintPreview;
