"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings_1 = require("~/settings");
/**
 * Module permettant de générer les fonds de carte OpenStreetMap.
 */
const OpenStreetMap = {
    layersOptions: [
        {
            name: 'OpenStreetMap',
            layerURL: 'http://tile.openstreetmap.org/${z}/${x}/${y}.png',
            permalinkId: 'osm.normal',
            imagePath: 'img/osm-normal.jpg'
        },
        {
            name: 'OpenCycleMap',
            layerURL: "https://tile.thunderforest.com/cycle/${z}/${x}/${y}.png",
            permalinkId: 'osm.cycle',
            imagePath: 'img/osm-cycle.jpg'
        }
    ],
    // Options utilisées pour construire les layers
    sharedOptions: {
        geocoderZoom: 12,
        attribution: 
        // Copyright
        '<div><a href="http://www.openstreetmap.org/copyright" target ="_blank" style="color: #111; font-size: 10px;">&copy; OpenStreetMap contributors</a></div>',
        customScales: [
            {
                scale: 500000,
                ratio: 3
            },
            {
                scale: 250000,
                ratio: 3
            },
            {
                scale: 100000,
                ratio: 3
            },
            {
                scale: 50000,
                ratio: 3
            },
            {
                scale: 25000,
                ratio: 3
            }
        ]
    },
    /**
     * Crée le fond de carte OSM.
     * Inspiré par http://api.ign.fr/tech-docs-js/examples/geoportalMap_webmercator.html
     */
    buildLayer: function (layerOptions) {
        let url = layerOptions.layerURL;
        if (url.indexOf('tile.thunderforest.com') > -1) {
            url = [url, '?apikey=', settings_1.settings.osmKey].join('');
        }
        const layer = new OpenLayers.Layer.OSM(layerOptions.name, url, Object.assign(Object.assign({}, this.sharedOptions), { permalinkId: layerOptions.permalinkId, imagePath: layerOptions.imagePath }));
        return layer;
    }
};
exports.default = OpenStreetMap;
