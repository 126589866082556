"use strict";
/**
 * Classe utilisataire
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildButtonPanel = void 0;
function buildButtonPanel($button, $panel) {
    const bp = {
        $button: $button,
        $panel: $panel,
        show(event) {
            if (this.$panel.is(':animated')) {
                return false;
            }
            this.$button.addClass('expanded');
            this.$panel.show();
        },
        hide(event) {
            if (this.$panel.is(':animated')) {
                return false;
            }
            this.$button.removeClass('expanded');
            this.$panel.hide();
        },
        toggle(event) {
            if (this.$button.hasClass('expanded')) {
                this.$panel.trigger('hide');
            }
            else {
                this.$panel.trigger('show');
            }
        }
    };
    $button.on('click', bp.toggle.bind(bp));
    $panel.on('show', bp.show.bind(bp))
        .on('hide', bp.hide.bind(bp))
        .on('toggle', bp.toggle.bind(bp));
}
exports.buildButtonPanel = buildButtonPanel;
;
