"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spainProjection = exports.swissProjection = exports.gpsProjection = exports.mapProjection = void 0;
Proj4js.defs["EPSG:3857"] = "+title=GoogleMercator +proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs";
Proj4js.defs["EPSG:4326"] = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
Proj4js.defs["EPSG:21781"] = "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs";
Proj4js.defs["EPSG:23030"] = "+proj=utm +zone=30 +ellps=intl +units=m +no_defs";
exports.mapProjection = new OpenLayers.Projection('EPSG:3857');
exports.gpsProjection = new OpenLayers.Projection('EPSG:4326');
exports.swissProjection = new OpenLayers.Projection('EPSG:21781');
exports.spainProjection = new OpenLayers.Projection('EPSG:23030');
