"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class BaseControl extends OpenLayers.Control {
    /**
     * Fonction utilisataire qui permet de faire en sorte que les événements de souris ou de clavier
     * réalisés dans l'objet DOM ne sont pas remontés à  Openlayers.
     *
     * Par exemple : dans une zone de texte, gauche ou droite fait bouger le curseur plutôt que déplace
     * la carte.
     *
     * @param {Object} div
     */
    keepEvents(div) {
        this.keepEventsDiv = new OpenLayers.Events(this, div, null, true);
        // IN THIS CASE triggerButton IS NOT NECESSARY
        const triggerButton = (event) => {
            var _a, _b;
            let element = OpenLayers.Event.element(event), buttonclick = (_b = (_a = this.map.events) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.buttonclick;
            if (element && buttonclick) {
                element = buttonclick.getPressedButton(element);
                if (element && OpenLayers.Element.hasClass(element, "olButton")) {
                    buttonclick.buttonClick(event);
                }
            }
        };
        const listeners = {
            "mousedown": (event) => {
                this.mousedown = true;
                triggerButton(event);
                OpenLayers.Event.stop(event, true);
            },
            "mousemove": (event) => {
                if (this.mousedown) {
                    OpenLayers.Event.stop(event, true);
                }
            },
            "mouseup": (event) => {
                if (this.mousedown) {
                    this.mousedown = false;
                    triggerButton(event);
                    OpenLayers.Event.stop(event, true);
                }
            },
            "click": (event) => {
                var element = OpenLayers.Event.element(event);
                triggerButton(event);
                OpenLayers.Event.stop(event, true);
            },
            "mouseout": (event) => {
                this.mousedown = false;
            },
            "dblclick": (event) => {
                var element = OpenLayers.Event.element(event);
                triggerButton(event);
                OpenLayers.Event.stop(event, true);
            },
            "touchstart": (event) => {
                OpenLayers.Event.stop(event, true);
            },
            "keydown": (event) => {
                OpenLayers.Event.stop(event, true);
            },
            scope: this,
        };
        this.keepEventsDiv.on(listeners);
    }
    ;
}
exports.default = BaseControl;
