"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadSettings = exports.settings = void 0;
exports.settings = {
    proxyUrl: "proxy?csurl=",
    localesUrl: "locales/",
};
function loadSettings() {
    Object.assign(exports.settings, JSON.parse(document.getElementById('settings').innerHTML));
}
exports.loadSettings = loadSettings;
