"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendedLayer = void 0;
const utils_1 = require("~/utils/utils");
class ExtendedLayer extends OpenLayers.Layer.Grid {
    static patch(layer) {
        // On applique quelques patchs
        const { redraw, hasCustomScales, initCustomResolutions, getServerResolution, initGriddedTiles, getRatio, getCustomScales, updateCustomResolutions } = ExtendedLayer.prototype;
        const patchedMethods = { redraw, hasCustomScales, initCustomResolutions, getServerResolution, initGriddedTiles, getRatio, getCustomScales, updateCustomResolutions };
        const extendedLayer = Object.assign(layer, patchedMethods);
        extendedLayer.initCustomResolutions();
        layer.events.register('moveend', extendedLayer, extendedLayer.updateCustomResolutions);
        return extendedLayer;
    }
    redraw() {
        const redraw = super.redraw();
        if (redraw) {
            this.updateCustomResolutions();
        }
        return redraw;
    }
    hasCustomScales() {
        var _a;
        return !!((_a = this.customScales) === null || _a === void 0 ? void 0 : _a.length);
    }
    initCustomResolutions() {
        var _a;
        // Si le fond de carte n'a pas de custom-scales
        if (!((_a = this.customScales) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        console.log('This layer has custom scales');
        // On intercales les résolutions sur-mesure
        var screenResolution = (0, utils_1.getScreenResolution)(); // px per m
        this.serverResolutions = this.resolutions;
        this.resolutions = [];
        this.ratios = [];
        let i = 0, l = this.serverResolutions.length;
        for (const customScale of this.customScales) {
            const customResolution = customScale.scale / screenResolution;
            for (let serverResolution = this.serverResolutions[i]; customResolution < serverResolution && i < l; serverResolution = this.serverResolutions[++i]) {
                this.resolutions.push(serverResolution);
                this.ratios.push(1);
            }
            const zoom = this.resolutions.length;
            customScale.zoom = zoom;
            this.resolutions.push(customResolution);
            this.ratios.push(customScale.ratio);
        }
        for (var serverResolution = this.serverResolutions[i]; i < l; serverResolution = this.serverResolutions[++i]) {
            this.resolutions.push(serverResolution);
            this.ratios.push(1);
        }
        this.numZoomLevels += this.customScales.length;
    }
    /**
     * Patch de la fonction OpenLayers.Layers.getServerResolution
     * Trouve la résolution à utiliser pour que le ratio entre la custom-resolution
     * et celle du provider soit entre 1 et 'ratio' (fourni en argument)
     */
    getServerResolution(resolution, ratio) {
        // this correspond à un layer de type Grid
        resolution = resolution || this.map.getResolution();
        if (this.serverResolutions &&
            OpenLayers.Util.indexOf(this.serverResolutions, resolution) === -1) {
            var i, serverResolution;
            var zoom = this.map.getZoom();
            ratio = ratio || this.ratios[zoom];
            for (i = this.serverResolutions.length - 1; i >= 0; i--) {
                serverResolution = this.serverResolutions[i];
                if (serverResolution >= resolution / ratio) {
                    resolution = serverResolution;
                    break;
                }
            }
            if (i === -1) {
                throw 'no appropriate resolution in serverResolutions';
            }
        }
        return resolution;
    }
    /**
     * Patch de la fonction OpenLayers.Layers.initGriddedTiles
     * Vu que les tiles seront rétrécies, ce patch permet de gérer une grille plus grande
     * que celle initialement prévue.
     */
    initGriddedTiles(bounds) {
        this.clearTileQueue();
        var ratio = this.getRatio();
        // work out mininum number of rows and columns; this is the number of
        // tiles required to cover the viewport plus at least one for panning
        var viewSize = this.map.getSize();
        var minRows = Math.ceil(viewSize.h / this.tileSize.h * ratio) +
            Math.max(1, this.buffer);
        var minCols = Math.ceil(viewSize.w / this.tileSize.w * ratio) +
            Math.max(1, this.buffer);
        var origin = this.getTileOrigin();
        var resolution = this.getServerResolution();
        var tileLayout = this.calculateGridLayout(bounds, origin, resolution);
        var tileoffsetx = Math.round(tileLayout.tileoffsetx); // heaven help us
        var tileoffsety = Math.round(tileLayout.tileoffsety);
        var tileoffsetlon = tileLayout.tileoffsetlon;
        var tileoffsetlat = tileLayout.tileoffsetlat;
        var tilelon = tileLayout.tilelon;
        var tilelat = tileLayout.tilelat;
        var startX = tileoffsetx;
        var startLon = tileoffsetlon;
        var rowidx = 0;
        var layerContainerDivLeft = parseInt(this.map.layerContainerDiv.style.left);
        var layerContainerDivTop = parseInt(this.map.layerContainerDiv.style.top);
        var tileData = [], center = this.map.getCenter();
        do {
            var row = this.grid[rowidx++];
            if (!row) {
                row = [];
                this.grid.push(row);
            }
            tileoffsetlon = startLon;
            tileoffsetx = startX;
            var colidx = 0;
            do {
                var tileBounds = new OpenLayers.Bounds(tileoffsetlon, tileoffsetlat, tileoffsetlon + tilelon, tileoffsetlat + tilelat);
                var x = tileoffsetx;
                x -= layerContainerDivLeft;
                var y = tileoffsety;
                y -= layerContainerDivTop;
                var px = new OpenLayers.Pixel(x, y);
                var tile = row[colidx++];
                if (!tile) {
                    tile = this.addTile(tileBounds, px);
                    this.addTileMonitoringHooks(tile);
                    row.push(tile);
                }
                else {
                    tile.moveTo(tileBounds, px, false);
                }
                var tileCenter = tileBounds.getCenterLonLat();
                tileData.push({
                    tile: tile,
                    distance: Math.pow(tileCenter.lon - center.lon, 2) +
                        Math.pow(tileCenter.lat - center.lat, 2)
                });
                tileoffsetlon += tilelon;
                tileoffsetx += this.tileSize.w;
            } while ((tileoffsetlon <= bounds.right + tilelon * this.buffer)
                || colidx < minCols);
            tileoffsetlat -= tilelat;
            tileoffsety += this.tileSize.h;
        } while ((tileoffsetlat >= bounds.bottom - tilelat * this.buffer)
            || rowidx < minRows);
        //shave off exceess rows and colums
        this.removeExcessTiles(rowidx, colidx);
        // store the resolution of the grid
        this.gridResolution = this.getServerResolution();
        //now actually draw the tiles
        tileData.sort(function (a, b) {
            return a.distance - b.distance;
        });
        for (var i = 0, ii = tileData.length; i < ii; ++i) {
            tileData[i].tile.draw();
        }
        this.moveGriddedTiles();
    }
    /**
     * Nouvelle fonction qui permet de retourner le ratio max utilié pour la résolution actuelle.
     */
    getRatio() {
        const zoom = this.map.getZoom();
        return this.ratios[zoom];
    }
    /**
     * Nouvelle fonction qui permet de retourner les zoom pour une échelle donnée
     */
    getCustomScales(scale) {
        var _a;
        return ((_a = this.customScales) === null || _a === void 0 ? void 0 : _a.filter(customScale => customScale.scale == scale)) || [];
    }
    /**
     * Nouvelle fonction qui met à jour les résolutions sur-mesure,
     * dépendante de la latitude
     */
    updateCustomResolutions() {
        // A ne faire qu'une fois. Pour l'instant je n'ai pas trouvé mieux
        this.events.unregister('moveend', this, this.updateCustomResolutions);
        const screenResolution = (0, utils_1.getScreenResolution)(); // px per m
        const map = this.map;
        const center = map.getCenter();
        const projectionFactor = map.getProjectionFactor(center);
        console.log('Update custom resolutions', { permalinkId: this.permalinkId, projectionFactor });
        for (const customScale of this.customScales || []) {
            const zoom = customScale.zoom;
            const customResolution = customScale.scale / screenResolution * projectionFactor;
            this.resolutions[zoom] = customResolution;
        }
    }
}
exports.ExtendedLayer = ExtendedLayer;
