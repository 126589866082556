"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logEvent = void 0;
function logEvent(name, data) {
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(Object.assign({ 'event': name }, data));
    }
}
exports.logEvent = logEvent;
exports.default = {
    logEvent,
};
