"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const locale_1 = require("~/services/locale");
const settings_1 = require("~/settings");
const utils_1 = require("./utils");
/**
 * Formulaire d'import d'itinéraire
 */
class TrackView {
    constructor() {
        this.$el = $('<form class="track-form"/>');
        // Ne rien faire à la soumission du formulaire
        this.$el.on("submit", e => e.preventDefault());
        // Bouton sur la carte
        const $importButton = $(`
      <div class="jgnButton importButton" title="${(0, locale_1.t)('track.help')}">
        <img src="${settings_1.settings.baseUrl}img/import.png" title="${(0, locale_1.t)('track.help')}"/>
      </div>`);
        this.$el.append($importButton);
        // Formulaire d'import
        const $importForm = $(`
      <div class="jgnPanel importPanel">
        <h3>${(0, locale_1.t)('track.description')}</h3>
        <div class="form-line first">
          <h4>URL</h4>
          <div class="right-column">
            <input type="text" name="url" size="35"/>
          </div>
        </div>
        <div class="form-line">
          <h4>${(0, locale_1.t)('track.format')}</h4>
          <div class="right-column">
            <input type="radio" name="format" value="GPX" checked="checked"/>GPX
            <input type="radio" name="format" value="KML"/>KML
          </div>
        </div>
        <div class="form-line">
          <h4>${(0, locale_1.t)('track.color')}</h4>
          <div class="right-column">
            <input type="text" name="strokeColor" class="color-picker" value="#991616"/>
          </div>
        </div>
        <div class="form-line">
          <h4>${(0, locale_1.t)('track.width')}</h4>
          <div class="right-column">
            <input type="text" name="strokeWidth" class="spinner" size="1" value="4"/>
          </div>
        </div>
        <div class="form-line">
          <h4>&nbsp;</h4>
          <div class="right-column">
            <input type="checkbox" name="zoomToLayer" checked="checked"/>
            ${(0, locale_1.t)('track.center')}
          </div>
        </div>
        <div class="form-line last buttons">
          <div id="track-import-button" class="button-flat">
            <button>${(0, locale_1.t)('track.submit')}</button>
          </div>
          <div class="button-separator">ou</div>
          <div id="track-cancel-button" class="button-flat-light">
            <button type="button">${(0, locale_1.t)('track.cancel')}</button>
          </div>
        </div>
      </div>`);
        this.$el.append($importForm.hide());
        // Mise en place des fonctionnalités / animations
        (0, utils_1.buildButtonPanel)($importButton, $importForm);
        // On construit les ui quand on en a besoin
        $importButton.one("click", (event, ui) => {
            // Colorpicker
            $('.color-picker', this.$el).spectrum({
                color: '#991616',
                showPalette: true,
                palette: [
                    ['black', 'white', '#991616'],
                    ['red', 'yellow', 'green'],
                    ['blue', 'violet', 'orange']
                ],
                preferredFormat: "hex",
                cancelText: (0, locale_1.t)('track.cancelColor'),
                chooseText: (0, locale_1.t)('track.submitColor'),
            });
            // Spinner
            /*
            $('.spinner', self.$el).spinner({
              min: 1,
              max: 12,
              stop: function() {console.log("a");}
            });
            */
            // Boutons
            $('#track-cancel-button', this.$el).on("click", () => {
                this.$importForm.trigger('hide');
            });
        });
        // Changement de l'image en fonction des événements
        this.$el
            .on("loadTrackStart", (event, ui) => {
            this.$importForm.trigger('hide');
            $('img', this.$importButton).attr('src', settings_1.settings.baseUrl + 'img/ajax-loader.gif');
        })
            .on("loadTrackEnd", (event, ui) => {
            $('img', this.$importButton).attr('src', settings_1.settings.baseUrl + 'img/import.png');
        });
    }
    get $importButton() {
        return $('.importButton', this.$el);
    }
    get $importForm() {
        return $('.importPanel', this.$el);
    }
    /**
     * Initialise le formulaire avec les données par défaut ou celles
     * contenues dans l'url.
     */
    loadSettings(settings) {
        $('input[name=url]', this.$el).val(settings.trackUrl);
        $('input[name=format]', this.$el).val([settings.trackFormat]);
        $('input[name=strokeColor]', this.$el).val(settings.trackColor);
        $('input[name=strokeWidth]', this.$el).val(settings.trackWidth);
    }
    /**
     * Retourne le contenu du formulaire.
     */
    getSettings() {
        const settings = {
            trackUrl: $('input[name=url]', this.$el).val(),
            trackFormat: $('input[name=format]:checked', this.$el).val(),
            trackColor: $('input[name=strokeColor]', this.$el).val(),
            trackWidth: $('input[name=strokeWidth]', this.$el).val(),
            zoomToLayer: ($('input[name=zoomToLayer]:checked', this.$el).val() != undefined),
        };
        return settings;
    }
}
exports.default = TrackView;
