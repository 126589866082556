"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const projections_1 = require("~/openlayers/projections");
/**
 * Ce module permet de générer les fonds de carte issus de Swisstopo.
 *
 * Pour les infos:
 *  - http://api.geo.admin.ch/main/wsgi/doc/build/services/sdiservices.html#wmts-description
 *  - http://wmts.geo.admin.ch/1.0.0/WMTSCapabilities.xml
 */
const Swisstopo = {
    layersOptions: [
        {
            name: 'Swisstopo',
            layer: 'ch.swisstopo.pixelkarte-farbe',
            permalinkId: 'swisstopo.carte',
            imagePath: 'img/swisstopo.jpg'
        }
    ],
    sharedOptions: {
        // Using http://spatialreference.org/ref/epsg/21781/
        projection: projections_1.swissProjection,
        maxExtent: new OpenLayers.Bounds(485869.5728, 76443.1884, 837076.5648, 299941.7864),
        // Using https://groups.google.com/forum/#!msg/geoadmin-api/k0uzGkMW20M/jbJk8to_sfoJ
        url: [
            "https://wmts0.geo.admin.ch/",
            "https://wmts1.geo.admin.ch/",
            "https://wmts2.geo.admin.ch/",
            "https://wmts3.geo.admin.ch/",
            "https://wmts4.geo.admin.ch/"
        ],
        matrixSet: "21781",
        format: "image/jpeg",
        requestEncoding: "REST",
        style: "default",
        dimensions: ['TIME'],
        params: { 'time': 'current' },
        tileOrigin: new OpenLayers.LonLat(420000.0, 350000.0),
        zoomOffset: 0,
        resolutions: [4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250, 1000, 750, 650, 500, 250, 100, 50, 20, 10, 5, 2.5, 2, 1.5, 1, 0.5],
        //numZoomLevels: 17,
        formatSuffix: 'jpeg',
        // Propriétés perso
        opacity: 1.0,
        geocoderZoom: 18,
        limitSizeToA4: true,
        customScales: [
            {
                scale: 500000,
                ratio: 3
            },
            {
                scale: 250000,
                ratio: 3
            },
            {
                scale: 100000,
                ratio: 3
            },
            {
                scale: 50000,
                ratio: 3
            },
            {
                scale: 25000,
                ratio: 3
            }
        ]
    },
    /**
     * Crée un fond de carte issu de Swisstopo.
     */
    buildLayer(layerOptions) {
        var layer = new OpenLayers.Layer.WMTS(Object.assign(Object.assign({}, this.sharedOptions), { name: layerOptions.name, layer: layerOptions.layer, permalinkId: layerOptions.permalinkId, imagePath: layerOptions.imagePath }));
        return layer;
    },
};
exports.default = Swisstopo;
